import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { SettingsComponent } from './Settings/settings.component';
import { NgChartsModule } from 'ng2-charts';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { reducers } from './Store/reducers';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { LeftNavComponent } from './Components/left-nav/left-nav.component';
import { LogoutComponent } from './Components/logout/logout.component';
import { HeaderComponent } from './Components/header/header.component';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from 'src/environments/environment';
import { GridModule } from '@app/Shared/modules/ag-grid.module';
import { MaterialModule } from '@app/Shared/modules/material.modules';
import { effects } from '@app/Store/effects';
import { ToastrModule } from 'ngx-toastr';
import { AlertModule } from './Shared/common-components/alert';
import { HttpErrorHandlerInterceptor } from './Services/Interceptors/http-error-handler.interceptor';
import { ServerlessOauthService, OauthSessionModule } from '@stg-js-ngx-core/oauth';
import { AuthInterceptor } from './Services/Interceptors/auth.interceptor';

@NgModule({
    declarations: [AppComponent, HeaderComponent, LeftNavComponent, LogoutComponent, SettingsComponent],
    imports: [
        BrowserAnimationsModule,
        BrowserModule,
        HttpClientModule,
        MaterialModule,
        GridModule,
        FormsModule,
        ReactiveFormsModule,
        AppRoutingModule,
        NgChartsModule,
        StoreModule.forRoot(reducers),
        StoreDevtoolsModule.instrument({
            maxAge: 25,
            logOnly: environment.production,
        }),
        EffectsModule.forRoot(effects),
        ToastrModule.forRoot(),
        AlertModule,
        OauthSessionModule.forRoot({
            totalMinutes: 20,
        }),
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpErrorHandlerInterceptor,
            multi: true,
        },
        ServerlessOauthService,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
