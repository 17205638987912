// src/app/store/reducers/index.ts
import { ActionReducerMap } from '@ngrx/store';
import { uiReducer } from './ui.reducer';
import { UiState } from '@app/Shared/interfaces/iUserInterface';
import { dashboardFeatureKey, dashboardFeatureReducer } from '../features/dashboard.feature';
import { iDashboardState } from '@app/Shared/interfaces/iDashboard';
import { authFeatureKey, authFeatureReducer } from '../features/auth.feature';
import { iAuthState } from '@app/Shared/interfaces/iAuth';

export interface AppState {
    ui: UiState;
    [dashboardFeatureKey]: iDashboardState;
    [authFeatureKey]: iAuthState
}

export const reducers: ActionReducerMap<AppState> = {
    ui: uiReducer,
    [dashboardFeatureKey]: dashboardFeatureReducer,
    [authFeatureKey]: authFeatureReducer
};
