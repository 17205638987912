import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AlertService, AlertType } from '../Utilities/alert.service';
import { catchError } from 'rxjs/operators';

@Injectable()
export class HttpErrorHandlerInterceptor implements HttpInterceptor {
    constructor(private alertService: AlertService) {}

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        let skipErrorHandler: string = 'false';
        let modifiedRequest: any;
        if (request.headers.has('skip-error-handler')) {
            skipErrorHandler = request.headers.get('skip-error-handler');
            modifiedRequest = request.clone({
                headers: request.headers.delete('skip-error-handler')
            });
        } else {
            modifiedRequest = request;
        }
        return next.handle(modifiedRequest).pipe(
            catchError((error: HttpErrorResponse) => {
                let showError = true;
                showError = skipErrorHandler == 'false';

                if (modifiedRequest.url.includes('/oauth/token')) {
                    return throwError(() => error);
                }

                if (this.isInternalError(error)) {
                    if (showError) {
                        this.alertService.alert(AlertType.Error, 'There is an error connecting to the server, please contact support', 'Request processing');
                    }

                    return throwError(() => error);
                }

                if (this.isUnAuthorizeRequest(error)) {
                    return throwError(() => error);
                }

                if (this.isCustomServerError(error)) {
                    if (showError) {
                        this.alertService.notify(AlertType.Error, '400 Server Error', 'Request processing');
                    }
                    return throwError(() => error);
                }

                if (this.isServerIsOutOfAccess(error)) {
                    if (showError) {
                        this.alertService.alert(AlertType.Error, 'The server is not available. Please try again later', 'Request processing');
                    }

                    return throwError(() => error);
                }

                return throwError(() => error);
            })
        );
    }

    private isUnAuthorizeRequest(error: HttpErrorResponse): boolean {
        return error.status == 401 || error.status == 403;
    }

    private isServerIsOutOfAccess(error: HttpErrorResponse): boolean {
        return error.status == 0;
    }

    private isCustomServerError(error: HttpErrorResponse): boolean {
        return error.status >= 400 && error.status < 500;
    }

    private isInternalError(error: HttpErrorResponse): boolean {
        return error.status >= 500;
    }
}
