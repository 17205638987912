import { iEdmx } from '../interfaces/iEntity';
import { iParsedEntity } from '../interfaces/iEntityParsed';
import { initialDashboardState } from '@app/Store/reducers/dashboard.reducer';

const applyDefaults = (entity: Partial<iParsedEntity>): iParsedEntity => {
    const defaultEntity: iParsedEntity = {
        ...initialDashboardState.data.entities[0], // Assuming at least one entity exists in the initial state
        ...entity
    };
    return defaultEntity;
};

export const parseEdmxData = (edmx: iEdmx): iParsedEntity[] => {

    const entityTypes =
        edmx['edmx:Edmx']['edmx:DataServices'].Schema.EntityType;
    if (!Array.isArray(entityTypes)) {
        throw new Error('Invalid EntityType format');
    }

    const entities: iParsedEntity[] = entityTypes.map((entityType) => {
        // Simplify property mappings to directly extract names.
        const properties = entityType.Property.map((prop) => ({
            name: prop.Name,
            type: prop.Type,
            nullable: prop.Nullable,
            maxLength: prop.MaxLength,
        }));

        // Consolidate the logic to handle navigation properties, assume it is always an array.
        const navigationProperties = (
            Array.isArray(entityType.NavigationProperty)
                ? entityType.NavigationProperty
                : [entityType.NavigationProperty]
        )
            .filter((navProp) => navProp) // Filter out any possible undefined values from the array
            .map((navProp) => ({
                name: navProp.Name,
                partner: navProp.Partner,
                referentialConstraint: navProp.ReferentialConstraint
                    ? {
                          property: navProp.ReferentialConstraint.Property,
                          referencedProperty:
                              navProp.ReferentialConstraint.ReferencedProperty,
                      }
                    : undefined,
            }));

        const entity: Partial<iParsedEntity> = {
            entityName: entityType.Name,
            key: entityType.Key.PropertyRef.Name, // Assumed that there is always a Key and PropertyRef
            properties,
            navigationProperties: navigationProperties,
        };

        return applyDefaults(entity);
    });

    return entities; // Directly return the array of iParsedEntity
};
