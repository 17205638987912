import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LogoutComponent } from './Components/logout/logout.component';
import { SettingsComponent } from './Settings/settings.component';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  {
    path: 'home',
    loadChildren: () => import('./Components/home/home.module').then(m => m.HomeModule),
    data: { pageTitle: 'Home' },
  },
  {
    path: 'data-explorer',
    loadChildren: () => import('./DataExplorer/data-explorer.module').then(m => m.DataExplorerModule),
    data: { pageTitle: 'Data Review' },
  },
  {
    path: 'reports',
    loadChildren: () => import('./Reports/reports.module').then(m => m.ReportsModule),
    data: { pageTitle: 'True Data Reports' }
  },
  {
    path: 'logout',
    component: LogoutComponent,
    data: { pageTitle: 'Logout' }
  },
  {
    path: 'settings',
    component: SettingsComponent,
    data: { pageTitle: 'Settings' },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
