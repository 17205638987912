import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogData, AlertComponent, AlertDialogData, ConfirmComponent } from '@app/Shared/common-components/alert';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(private readonly toastr: ToastrService, private dialog: MatDialog) { }

  public notify(
    type: AlertType = AlertType.Success,
    message: string | undefined,
    title: string | undefined = 'Process Request',
    timeOut: number = 10000,
    enableHtml: boolean = false
  ) {
    const CONFIG = { timeOut: timeOut, extendedTimeOut: 1000, enableHtml: enableHtml, positionClass: "toast-bottom-right", animate: 'flyRight', closeButton: true, progressBar: true, };
    switch (type) {
      case AlertType.Success:
        this.toastr.success(message, title, CONFIG);
        break;
      case AlertType.Warning:
        this.toastr.warning(message, title, CONFIG);
        break;
      case AlertType.Error:
        this.toastr.error(message, title, CONFIG);
        break;
      case AlertType.Info:
        this.toastr.info(message, title, CONFIG);
        break;
    }
  }

  confirm(message: string, title: string, yesCallback: () => void, noCallback?: () => void,yesText:string ='Yes' , noText:string = 'No'): void {
    const dialogRef = this.dialog.open(ConfirmComponent, {
      width: '250px',
      data: { title: title, message: message , yesText: yesText , noText:noText} as ConfirmDialogData
    });
    dialogRef.afterClosed().subscribe(result => {
      if (JSON.parse(result)) {
        yesCallback();
      } else {
        if (noCallback) {
          noCallback();
        }
      }
    });
  }

  alert(type:AlertType, message: string, title: string, oKText:string ='OK'): void {
    const dialogRef = this.dialog.open(AlertComponent, {
      width: '500px',
      data: { title: title, message: message , oKText: oKText, type: type} as AlertDialogData
    });
  }
}

export enum AlertType {
  Info = "info",
  Warning = "warning",
  Error = "error",
  Success = "success"
}

