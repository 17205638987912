import { createFeature, createSelector } from "@ngrx/store";
import { uiReducer } from "../reducers/ui.reducer";
import { AppState } from "../reducers";
import { UiState } from "@app/Shared/interfaces/iUserInterface";

export const uiFeature = createFeature({
    name: 'ui',
    reducer: uiReducer,
});

const selectUiState = (state: AppState) => state.ui;


export const selectLoading = createSelector(
    selectUiState,
    (state: UiState) => state.isLoading
);

