// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    baseUrls: {
      sso: 'https://ssodev.compassmanager.com',
      rbac: 'https://adamsdev.compassmanager.com',
      oauthLambda: 'https://auth.cg-truedata-dev.com/api/oauth2.0', 
      oData: 'https://api.cg-truedata-dev.com/OData/v1/',
      api: 'https://alb.cg-truedata-dev.com/',
      redirectUri: 'https://auth.cg-truedata-dev.com/api/oauth2.0'
    },
    omsConfig: {
      ssoClientId: 'j4Dy363UCPLr8hNHcnUAF4IYe7SktSR5',
      clientId: 'j4Dy363UCPLr8hNHcnUAF4IYe7SktSR5',
      clientSecret: 'SiSyMruwhEtK18M00GC40aYV1pxPVqso',
      authUrl: 'https://ssodev.compassmanager.com/oauth2.0/accessToken',
      grantType: 'client_credentials'
    }
  };
  