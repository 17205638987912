/* eslint-disable @typescript-eslint/no-explicit-any */

import { createAction, props } from '@ngrx/store';
import { iParsedEntity } from '@app/Shared/interfaces/iEntityParsed';
// import { iODataQueryOptions } from '@app/Shared/interfaces/iOdataQueryOptions';
import { iEntityDetail, iEntityDetails } from '@app/Shared/interfaces/iEntityDetails';
import { iMasterTable } from '@app/Shared/interfaces/iMasterTable';
import { AddParentIn } from '@app/Shared/models/add-parent-in.model';
import { RemoveParentIn } from '@app/Shared/models/remove-parent-in.model';
import { ChangeParentChildLinkKey } from '@app/Shared/models/change-parent-child-link-key.model';



/** Data selected Tab actions */
export const changeSelectedTab = createAction('[DASHBOARD] CHANGE_SELECTED_TAB', props<{ selectedTab: string }>());

/** Data Entity management actions */
export const loadDataEntities = createAction('[DASHBOARD] LOAD_DATA_ENTITIES', props<{ query: string }>());
export const loadDataEntitySuccess = createAction('[DASHBOARD] LOAD_DATA_ENTITIES_SUCCESS', props<{ data: iParsedEntity[] }>());
export const loadDataEntityFailure = createAction('[DASHBOARD] LOAD_DATA_ENTITIES_FAILURE', props<{ error: string }>());

// Load Master Tables
export const loadDataMasterTables = createAction('[DASHBOARD] LOAD_DATA_MASTER_TABLES', props<{ query: string }>());
export const loadDataMasterTablesSuccess = createAction('[DASHBOARD] LOAD_DATA_MASTER_TABLES_SUCCESS', props<{ masterTables: iMasterTable[] }>());
export const loadDataMasterTablesFailure = createAction('[DASHBOARD] LOAD_DATA_MASTER_TABLES_FAILURE', props<{ error: string }>());

/**  Data Entity Selected management actions */
export const selectedEntity = createAction('[DASHBOARD] SELECTED_ENTITY', props<{ selectedEntity: iParsedEntity }>());
export const loadSelectedEntityRowData = createAction('[Dashboard] LOAD_SELECTED_ENTITY_ROW_DATA', props<{ entityName: string }>());
export const loadSelectedEntityRowDataSuccess = createAction('[Dashboard] LOAD_SELECTED_ENTITY_ROW_DATA_SUCCESS', props<{ rowData: any[] }>());
export const loadSelectedEntityRowDataFailure = createAction('[Dashboard] LOAD_SELECTED_ENTITY_ROW_DATA_FAILURE', props<{ error: any }>());

// Store Records
export const storeEntityDetails = createAction('[DASHBOARD] STORE_ENTITY_DETAILS', props<{ details: iEntityDetails }>());
export const setSelectedRecords = createAction('[Dashboard] SET_SELECTED_RECORDS', props<{ selectedRecords: any[] }>());

export const storeSelectedRecords = createAction('[Dashboard] STORE_SELECTED_RECORDS', props<{ selectedRecords: any[] }>());
export const storeParentChildLinkDataSuccess = createAction(
    '[Dashboard] STORE_PARENT_CHILD_LINK_DATA_SUCCESS',
    props<{ parentLinksData: iEntityDetail[]; childLinksData: iEntityDetail[] }>()
);
export const storeParentChildLinkDataFailure = createAction('[Dashboard] STORE_PARENT_CHILD_LINK_DATA_FAILURE', props<{ error: any }>());

// Store Record Details
export const fetchParentData = createAction('[Dashboard] FETCH_PARENT_DATA', props<{ masterTable: string; masterKey: string }>());
export const fetchParentDataSuccess = createAction('[Dashboard] FETCH_PARENT_DATA_SUCCESS', props<{ parentData: any[] }>());
export const fetchParentDataFailure = createAction('[Dashboard] FETCH_PARENT_DATA_FAILURE', props<{ error: any }>());

export const fetchChildData = createAction('[Dashboard] FETCH_CHILD_DATA', props<{ masterTable: string; masterKey: string }>());
export const fetchChildDataSuccess = createAction('[Dashboard] FETCH_CHILD_DATA_SUCCESS', props<{ childData: any[] }>());
export const fetchChildDataFailure = createAction('[Dashboard] FETCH_CHILD_DATA_FAILURE', props<{ error: any }>());

export const fetchMatchData = createAction('[DaDashboardta]  FETCH_MATCH_DATA', props<{ masterTable: string; masterKey: string }>());
export const fetchMatchDataSuccess = createAction('[Dashboard] FETCH_MATCH_DATA_SUCCESS', props<{ matchData: any[] }>());
export const fetchMatchDataFailure = createAction('[Dashboard]  FETCH_MATCH_DATA_FAILURE', props<{ error: any }>());

export const fetchMergeData = createAction('[Dashboard] FETCH_MERGE_DATA', props<{ masterTable: string; masterKey: string }>());
export const fetchMergeDataSuccess = createAction('[Dashboard] FETCH_MERGE_DATA_SUCCESS', props<{ mergeData: any[] }>());
export const fetchMergeDataFailure = createAction('[Dashboard] FETCH_MERGE_DATA_FAILURE', props<{ error: any }>());

// Parent Actions 
export const addParentData = createAction('[Dashboard] ADD_PARENT_DATA', props<AddParentIn>());
export const addParentDataSuccess = createAction('[Dashboard] ADD_PARENT_DATA_SUCCESS', props<{masterKey: string, masterTable: string}>());
export const addParentDataFailure = createAction('[Dashboard] ADD_PARENT_DATA_FAILURE', props<{ masterKey: number }>());

export const removeParentData = createAction('[Dashboard] REMOVE_PARENT_DATA', props<RemoveParentIn>());
export const removeParentDataSuccess = createAction('[Dashboard] REMOVE_PARENT_DATA_SUCCESS', props<{masterKey: string, masterTable: string}>());
export const removeParentDataFailure = createAction('[Dashboard] REMOVE_PARENT_DATA_FAILURE', props<{ masterKey: number }>());

export const changeParentChildData = createAction('[Dashboard] CHANGE_PARENTCHILD_DATA', props<ChangeParentChildLinkKey>());
export const changeParentChildDataSuccess = createAction('[Dashboard] CHANGE_PARENTCHILD_DATA_SUCCESS', props<{masterKey: string, masterTable: string, newMasterkey: string}>());
export const changeParentChildDataFailure = createAction('[Dashboard] CHANGE_PARENTCHILD_DATA_FAILURE', props<{ masterKey: number }>());

export const changeParentGridSelectedData = createAction('[Dashboard] CHANGE_PARENT_GRID_SELECTED_DATA', props<{ selectedRow: any }>());
export const changeChildGridSelectedData = createAction('[Dashboard] CHANGE_CHILD_GRID_SELECTED_DATA', props<{ selectedRow: any }>());


/** Query Parameter Management */
// export const setQueryParams = createAction('[QUERY] SET_QUERY_PARAMS', props<{ queryParams: iODataQueryOptions }>());
