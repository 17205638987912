export const pluralize = (entityName: string): string => {
    if (entityName.endsWith('s')) {
        return entityName + 'es';
    }
    return entityName + 's';
};

export const extractParts = (navProp: string): string[] => {
    return navProp.replace('Links', '').split(/(?=[A-Z])/);
};

export const extractParentTable = (entityName: string, navProp: string): string => {
    const regex = new RegExp(`(${entityName})Links$`);
    if (regex.test(navProp)) {
        return navProp.replace(regex, '');
    }
    return navProp.replace('Links', '');
};

export const extractChildTable = (entityName: string, link: string): string => {
    return link.replace('Links', '').replace(new RegExp(`^${entityName}`, 'i'), '');
};

export const extractMatchTable = (entityName: string, link: string): string => {
    return link.replace('Links', '').replace(new RegExp(`^${entityName}`, 'i'), '');
};

export const extractMergeTable = (entityName: string, link: string): string => {
    return link.replace('Links', '').replace(new RegExp(`^${entityName}`, 'i'),'');
}