import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { from, Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '@app/Store';
import { selectToken } from '@app/Store/features/auth.feature';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private store: Store<AppState>) {}
    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (request.url.includes('/oauth/token') || request.url.includes('oauth2.0/accessToken') || request.url.includes('oauth2.0/profile')  ) {
            return next.handle(request); // Bypass the interceptor
        }

        return this.store.select(selectToken).pipe(
            switchMap((token: string | null) => {

                if (!token) {
                    return next.handle(request);
                }

                let headers = request.headers;
                // Ask 
                // We are using the token which get from store and add it to header for Autorization
                // But Autorization header is already full with another token which I think might be injected by OAuth module.
                //  Which Token is important, ???   DO I need to include the token in header or remove it?
                headers = headers.set('Content-Type', 'application/json').set('Authorization', `Bearer ${token}`);

                if (!headers.has('Accept')) {
                    headers = headers.set('Accept', 'application/json');
                }

                const newReq = request.clone({ headers });
                return next.handle(newReq);
            })
        );
    }
}
