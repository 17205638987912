import { createFeature, createSelector } from '@ngrx/store';
import { dashboardReducer } from '../reducers/dashboard.reducer';
import { AppState } from '../reducers';
import { iDashboardState } from '@app/Shared/interfaces/iDashboard';

export const dashboardFeature = createFeature({
    name: 'dashboard',
    reducer: dashboardReducer,
});

const selectDashboardState = (state: AppState) => state.dashboard;

export const {
    name: dashboardFeatureKey,
    reducer: dashboardFeatureReducer,
    selectData,
    selectQueryParams,
} = dashboardFeature;

export const selectEntities = createSelector(
    selectDashboardState,
    (state: iDashboardState) => state.data.entities
);

export const selectParentGridCurrentRowData = createSelector(
    selectDashboardState,
    (state: iDashboardState) => state.data.selectedParentGridData
);

export const selectChildGridCurrentRowData = createSelector(
    selectDashboardState,
    (state: iDashboardState) => state.data.selectedChildGridData
);

export const selectTabChange = createSelector(
    selectDashboardState,
    (state: iDashboardState) => state.data.selectedTab
);

export const selectEntityNames = createSelector(
    selectDashboardState,
    (state: iDashboardState) =>
        state.data.entities.map((entity) => entity.entityName)
);

// Selected Entity Selector
export const selectSelectedEntity = createSelector(
    selectDashboardState,
    (state: iDashboardState) => state.data.selectedEntity
);

export const selectEntityRowData = createSelector(
    selectSelectedEntity,
    (selectedEntity) => (selectedEntity ? selectedEntity.rowData : [])
);

export const selectSelectedRecords = createSelector(
    selectSelectedEntity,
    (selectedEntity) => selectedEntity?.selectedRecords || []
);

export const selectIsSingleRowSelected = createSelector(
    selectSelectedEntity,
    (selectedEntity) => selectedEntity?.isSingleRecord
);

// Select MasterTable
export const selectMasterTables = createSelector(
    selectDashboardState,
    (state: iDashboardState) => state.data.masterTables
);

// Select Parent Links Data
export const selectParentLinksData = createSelector(
    selectSelectedRecords,
    (selectedRecords) => (selectedRecords.length > 0 ? selectedRecords[0].details.parentData : [])
);

// Select Child Links Data
export const selectChildLinksData = createSelector(
    selectSelectedRecords,
    (selectedRecords) => (selectedRecords.length > 0 ? selectedRecords[0].details.childData : [])
);

// Select Match Data
export const selectMatchData = createSelector(
    selectSelectedRecords,
    (selectedRecords) => (selectedRecords.length > 0 ? selectedRecords[0].details.matchData : [])
);

// Select Merge Data 
export const selectMergeData = createSelector(
    selectSelectedRecords,
    (selectedRecords) => (selectedRecords.length > 0 ? selectedRecords[0].details.mergeData : [])
);

// Dashboard Error Handling
export const selectError = createSelector(
    selectDashboardState,
    (state: iDashboardState) => state.error
);

export const dashboardFeatureSelectors = {
    ...dashboardFeature,
    selectEntities,
    selectSelectedEntity,
    selectEntityNames,
    selectEntityRowData,
    selectSelectedRecords,
    selectIsSingleRowSelected,
    selectMasterTables,
    selectParentLinksData,
    selectChildLinksData,
    selectMatchData,
    selectMergeData,
    selectError,
    selectTabChange,
    selectParentGridCurrentRowData,
    selectChildGridCurrentRowData
};

export const {
    selectEntities: dashboardFeatureSelectEntities,
    selectSelectedEntity: dashboardFeatureSelectSelectedEntity,
    selectEntityNames: dashboardFeatureSelectEntityNames,
    selectEntityRowData: dashboardFeatureSelectEntityRowData,
    selectSelectedRecords: dashboardFeatureSelectSelectedRecords,
    selectIsSingleRowSelected: dashboardFeatureSelectSingleRowSelected,
    selectMasterTables: dashboardFeatureSelectMasterTables,
    selectParentLinksData: dashboardFeatureSelectParentLinksData,
    selectChildLinksData: dashboardFeatureSelectChildLinksData,
    selectError: dashboardFeatureSelectError,
} = dashboardFeatureSelectors;

