import { iAuthState } from '@app/Shared/interfaces/iAuth';
import * as AuthActions from '../actions/auth-actions';
import { createReducer, on } from '@ngrx/store';

export const initialAuthState: iAuthState = {
    token: null,
    error: null,
};
export const authReducer = createReducer(
    initialAuthState,
    on(AuthActions.getToken, (state) => ({ ...state, error: null })),
    on(AuthActions.getTokenSuccess, (state, { token }) => ({ ...state, token, error: null })),
    on(AuthActions.getTokenFailure, (state, { error }) => ({ ...state, token: null, error })),
    on(AuthActions.clearToken, (state) => ({ ...state, token: null, error: null }))
);
