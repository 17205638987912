import { LoggerService } from './../../Services/logger.service';
import { OdataService } from '@app/Services/o-data.service';
import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { DASHBOARD_IMAGES } from '@app/Shared/constants/td-constants';
import { iButtonItem } from '@app/Shared/interfaces/iMenu';
import { Subscription } from 'rxjs';
import { Stream } from 'stream';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
    @Input() mini!: boolean; // To receive the mini state
    @Output() toggleMiniToParent = new EventEmitter<void>(); // To emit an event to toggle mini state

    public logo: string = DASHBOARD_IMAGES.MENU_LOGO;
    public buttonList: iButtonItem[] = [];
    public data: string = ''; //declaring: similar to let const var
    public subscriptionButtonList!: Subscription; // Implements and manages subscriptions
    //injecting the service
    constructor(
        private oDataService: OdataService,
        private loggerService: LoggerService
    ) {}

    ngOnInit(): void {
        this.subscriptionButtonList = this.oDataService
            .getButtonList()
            .subscribe((buttonData) => {
                this.buttonList = buttonData;
             });
    }

    onToggleMini(): void {
        this.toggleMiniToParent.emit(); // Emit the toggle event
    }
    ngOnDestroy(): void {
        this.subscriptionButtonList.unsubscribe();
    }
}

