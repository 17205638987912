<mat-toolbar>
    <mat-toolbar-row>
        <button color="primary" mat-icon-button (click)="onToggleMini()">
            <mat-icon>menu</mat-icon>
        </button>
        <span class="toolbar-logo">
            <a routerLink="home" class="toolbar-logo" aria-label="Homepage">
                <img [src]="logo" alt="True Data Logo">
            </a></span>
        <span class="toolbar-spacer"></span>
        <div class="app-header-menu">
            <button *ngFor="let button of buttonList" mat-icon-button class="{{button.title}}-icon " color="primary" aria-label=" icon-button with email icon">
                <mat-icon class="material-symbol-outlined">{{button.icon}}</mat-icon>
            </button>
            
        </div>
    </mat-toolbar-row>
</mat-toolbar>
