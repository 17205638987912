import { LoggerService } from './../../Services/logger.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as DashboardActions from '../actions/dashboard-actions';
import * as UiActions from '../actions/ui-actions';
import { Store } from '@ngrx/store';
import { AppState } from '../reducers';
import { OdataService } from '@app/Services/o-data.service';
import { iParsedEntity } from '@app/Shared/interfaces/iEntityParsed';
import { extractChildTable, extractParentTable, extractMatchTable, extractMergeTable, extractParts, pluralize } from '@app/Shared/util/stringManipulation';
import { iEntityRelationship } from '@app/Shared/interfaces/iEntityRelationship';
import { iRelationshipRequest } from '@app/Shared/interfaces/iRelationshipRequest';
import { AddParentIn } from '@app/Shared/models/add-parent-in.model';
import { AlertService, AlertType } from '@app/Services/Utilities/alert.service';
import { RemoveParentIn } from '@app/Shared/models/remove-parent-in.model';
import { ChangeParentChildLinkKey } from '@app/Shared/models/change-parent-child-link-key.model';

@Injectable()
export class DashboardEffects {
    public cn!: string;

    constructor(
        private actions$: Actions,
        private odataService: OdataService,
        private store: Store<AppState>,
        private con: LoggerService,
        private alertService: AlertService
    ) {}

    loadEntity$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DashboardActions.loadDataEntities),
            switchMap(({ query }) => {
                this.store.dispatch(UiActions.startLoading());
                return this.odataService.getMetaData(query).pipe(
                    map((data) => DashboardActions.loadDataEntitySuccess({ data })),
                    tap(() => this.store.dispatch(UiActions.stopLoading())),
                    catchError((error) => {
                        this.store.dispatch(UiActions.stopLoading());
                        return of(DashboardActions.loadDataEntityFailure({ error }));
                    })
                );
            })
        )
    );

    loadMasterTables$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DashboardActions.loadDataMasterTables),
            switchMap(({ query }) => {
                this.store.dispatch(UiActions.startLoading());
                return this.odataService.getOdata(query).pipe(
                    map((masterTables) => DashboardActions.loadDataMasterTablesSuccess({ masterTables })),
                    tap(() => this.store.dispatch(UiActions.stopLoading())),
                    catchError((error) => {
                        this.store.dispatch(UiActions.stopLoading());
                        return of(DashboardActions.loadDataMasterTablesFailure({ error }));
                    })
                );
            })
        )
    );

    loadSelectedEntityRowData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DashboardActions.loadSelectedEntityRowData),
            switchMap(({ entityName }) => {
                this.store.dispatch(UiActions.startLoading());
                return this.odataService.getOdata(entityName).pipe(
                    map((rowData) => DashboardActions.loadSelectedEntityRowDataSuccess({ rowData })),
                    tap(() => this.store.dispatch(UiActions.stopLoading())),
                    catchError((error) => {
                        console.error('Load Selected Entity Row Data Error:', error);
                        this.store.dispatch(UiActions.stopLoading());
                        return of(DashboardActions.loadSelectedEntityRowDataFailure({ error }));
                    })
                );
            })
        )
    );

    fetchParentData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DashboardActions.fetchParentData),
            switchMap(({ masterTable, masterKey }) => {
                this.store.dispatch(UiActions.startLoading());
                return this.odataService.getParentData(masterTable, masterKey).pipe(
                    map((parentData) => {
                        console.log('Parent data fetched:', parentData); // Debugging statement
                        return DashboardActions.fetchParentDataSuccess({ parentData });
                    }),
                    tap(() => this.store.dispatch(UiActions.stopLoading())),
                    catchError((error) => {
                        console.error('Fetch Parent Data Error:', error);
                        this.store.dispatch(UiActions.stopLoading());
                        return of(DashboardActions.fetchParentDataFailure({ error }));
                    })
                );
            })
        )
    );

    fetchChildData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DashboardActions.fetchChildData),
            switchMap(({ masterTable, masterKey }) => {
                this.store.dispatch(UiActions.startLoading());
                return this.odataService.getChildData(masterTable, masterKey).pipe(
                    map((childData) => {
                        console.log('Child data fetched:', childData); // Debugging statement
                        return DashboardActions.fetchChildDataSuccess({ childData });
                    }),
                    tap(() => this.store.dispatch(UiActions.stopLoading())),
                    catchError((error) => {
                        console.error('Fetch Child Data Error:', error);
                        this.store.dispatch(UiActions.stopLoading());
                        return of(DashboardActions.fetchChildDataFailure({ error }));
                    })
                );
            })
        )
    );

    fetchMatchData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DashboardActions.fetchMatchData),
            switchMap(({ masterTable, masterKey }) => {
                this.store.dispatch(UiActions.startLoading());
                return this.odataService.getMatchData(masterTable, masterKey).pipe(
                    map((matchData) => {
                        console.log('Match data fetched:', matchData); //Debugging statement
                        return DashboardActions.fetchMatchDataSuccess({ matchData });
                    }),
                    tap(() => this.store.dispatch(UiActions.stopLoading())),
                    catchError((error) => {
                        console.error('Fetch Match Data Error:', error);
                        this.store.dispatch(UiActions.stopLoading());
                        return of(DashboardActions.fetchMatchDataFailure({ error }));
                    })
                );
            })
        )
    );

    fetchMergeData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DashboardActions.fetchMergeData),
            switchMap(({ masterTable, masterKey }) => {
                this.store.dispatch(UiActions.startLoading());
                return this.odataService.getMergeData(masterTable, masterKey).pipe(
                    map((mergeData) => {
                        console.log('Merge data fetched:', mergeData); //Debugging statement
                        return DashboardActions.fetchMergeDataSuccess({ mergeData });
                    }),
                    tap(() => this.store.dispatch(UiActions.stopLoading())),
                    catchError((error) => {
                        console.error('Fetch Merge Data Error:', error);
                        this.store.dispatch(UiActions.stopLoading());
                        return of(DashboardActions.fetchMergeDataFailure({ error }));
                    })
                );
            })
        )
    );

    addParent$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DashboardActions.addParentData),
            switchMap((data: AddParentIn) => {
                const sendModel: AddParentIn = {
                    linkTable: data.linkTable,
                    masterKey: data.masterKey,
                    masterTable: data.masterTable,
                    newRelationKey: data.newRelationKey,
                    relationship: data.relationship,
                };

                this.store.dispatch(UiActions.startLoading());
                return this.odataService.addParent(sendModel).pipe(
                    map((res) => {
                        this.store.dispatch(DashboardActions.fetchParentData({ masterTable: data.masterTable.toString(), masterKey: data.masterKey.toString()}));
                        return DashboardActions.addParentDataSuccess({ masterKey: data.masterKey.toString(), masterTable: data.masterTable.toString() });
                    }),
                    tap(() => this.store.dispatch(UiActions.stopLoading())),
                    catchError((error) => {
                        this.store.dispatch(UiActions.stopLoading());
                        return of(DashboardActions.addParentDataFailure({ masterKey: data.masterKey }));
                    })
                );
            })
        )
    );

    addParentSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(DashboardActions.addParentDataSuccess),
                tap(({ masterKey }) => {
                    this.alertService.alert(AlertType.Success, `Parent ${masterKey} successfully linked to the following Child record: *****`, '');
                })
            ),
        { dispatch: false }
    );

    addParentFailure$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(DashboardActions.addParentDataFailure),
                tap(({ masterKey }) => {
                    this.alertService.alert(AlertType.Error, `Parent ${masterKey} Not Linked:Error Occurred: Please Check Master Key Value`, '');
                })
            ),
        { dispatch: false }
    );

    removeParent$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DashboardActions.removeParentData),
            switchMap((data: RemoveParentIn) => {
                const sendModel: RemoveParentIn = {
                    linkTable: data.linkTable,
                    masterKey: data.masterKey,
                    masterTable: data.masterTable,
                    linkKey: data.linkKey,
                };

                this.store.dispatch(UiActions.startLoading());
                return this.odataService.removeParent(sendModel).pipe(
                    map((res) => {
                        this.store.dispatch(DashboardActions.changeParentGridSelectedData({selectedRow: null}))
                        this.store.dispatch(DashboardActions.fetchParentData({ masterTable: data.masterTable.toString(), masterKey: data.masterKey.toString()}));
                        return DashboardActions.removeParentDataSuccess({ masterKey: data.masterKey.toString(), masterTable: data.masterTable.toString() });
                    }),
                    tap(() => this.store.dispatch(UiActions.stopLoading())),
                    catchError((error) => {
                        this.store.dispatch(UiActions.stopLoading());
                        return of(DashboardActions.removeParentDataFailure({ masterKey: data.masterKey }));
                    })
                );
            })
        )
    );

    removeParentSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(DashboardActions.removeParentDataSuccess),
                tap(({ masterKey }) => {
                    this.alertService.alert(AlertType.Success, `Parent ${masterKey} successfully linked to the following Child record: *****`, '');
                })
            ),
        { dispatch: false }
    );

    removeParentFailure$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(DashboardActions.removeParentDataFailure),
                tap(({ masterKey }) => {
                    this.alertService.alert(AlertType.Error, `Parent Master Key not found. `, '');
                })
            ),
        { dispatch: false }
    );

    changeParentChild$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DashboardActions.changeParentChildData),
            switchMap((data: ChangeParentChildLinkKey) => {
                const sendModel: ChangeParentChildLinkKey = {
                    linkTable: data.linkTable,
                    masterKey: data.masterKey,
                    masterTable: data.masterTable,
                    linkKey: data.linkKey,
                    newMasterKey: data.newMasterKey,
                    relationship: data.relationship,
                };

                this.store.dispatch(UiActions.startLoading());
                return this.odataService.changeParentChildLinkKey(sendModel).pipe(
                    map((res) => {
                        this.store.dispatch(DashboardActions.fetchParentData({ masterTable: data.masterTable.toString(), masterKey: data.masterKey.toString()}));
                        return DashboardActions.changeParentChildDataSuccess({ masterKey: data.masterKey.toString(), masterTable: data.masterTable.toString(), newMasterkey: data.newMasterKey.toString() });
                    }),
                    tap(() => this.store.dispatch(UiActions.stopLoading())),
                    catchError((error) => {
                        this.store.dispatch(UiActions.stopLoading());
                        return of(DashboardActions.changeParentChildDataFailure({ masterKey: data.masterKey }));
                    })
                );
            })
        )
    );

    changeParentChildSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(DashboardActions.changeParentChildDataSuccess),
                tap(({ masterKey, newMasterkey }) => {
                    this.alertService.alert(AlertType.Success, `Link Successfully reassigned from (old) Parent ${masterKey}  to (new) Parent ${newMasterkey}:`, '');
                })
            ),
        { dispatch: false }
    );

    changeParentChildFailure$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(DashboardActions.changeParentChildDataFailure),
                tap(({ masterKey }) => {
                    this.alertService.alert(AlertType.Error, `Parent ${masterKey} Not Linked:Error Occurred: Please Check Master Key Value`, '');
                })
            ),
        { dispatch: false }
    );

    private getNavigationPropertiesWithLinks(entity: iParsedEntity): string[] {
        return entity.navigationProperties.filter((prop) => prop.name.endsWith('Links')).map((prop) => prop.name);
    }

    private determineParentChildLinks(entityName: string, navProps: string[]): iEntityRelationship {
        const parentLinksData: string[] = [];
        const childLinksData: string[] = [];

        navProps.forEach((navProp) => {
            const parts = extractParts(navProp);
            if (parts.length < 2) {
                return;
            }

            const closestPartToLinks = parts[parts.length - 1];

            if (entityName.toLowerCase() === closestPartToLinks.toLowerCase()) {
                parentLinksData.push(navProp);
            } else {
                childLinksData.push(navProp);
            }
        });

        return { parentLinksData, childLinksData };
    }

    private formulateODataRequest(
        entityName: string,
        selectedRecords: any[],
        parentChildRelationship: iEntityRelationship,
        isSingleRowSelected: boolean
    ): iRelationshipRequest {
        const entityKey = `${entityName}Trukey`;
        const parentTable = pluralize(entityName);
        const { parentLinksData, childLinksData } = parentChildRelationship;

        const createRequest = (table: string, expandPart: string) => {
            if (isSingleRowSelected) {
                const [rowOne] = selectedRecords;
                return `${table}(${rowOne[entityKey]})?$expand=${expandPart}`;
            } else {
                const filters = selectedRecords.map((row) => `${entityKey} eq ${row[entityKey]}`).join(' or ');
                return `${table}?$filter=${filters}&$expand=${expandPart}`;
            }
        };

        const parentRequests = parentLinksData.map((link) => {
            return {
                link,
                sublink: extractParentTable(entityName, link),
                linkRequest: createRequest(parentTable, `${link}($expand=${extractParentTable(entityName, link)})`),
            };
        });

        const childRequests = childLinksData.map((link) => {
            return {
                link,
                sublink: extractChildTable(entityName, link),
                linkRequest: createRequest(parentTable, `${link}($expand=${extractChildTable(entityName, link)})`),
            };
        });

        /* const matchRequests = matchData.map((link) => {
            return { 
                link,
                sublink: extractMatchTable(entityName, link),
                linkRequest: createRequest(parentTable, `${link}($expand=${extractMatchTable(entityName, link)})`),
            };
        })

        const mergeRequests = mergeData.map((link) => {
            return {
                link,
                sublink: extractMergeTable(entityName, link),
                linkRequest: createRequest(parentTable, `${link}($expand=${extractMergeTable(entityName, link)})`),
            };
        })
 */

        return { parentRequests, childRequests };
    }
}
