import { Component, ViewChild, OnInit, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { ServerlessOAuthConfig, ServerlessOauthService, ApplicationConfigurationService} from '@stg-js-ngx-core/oauth';
import { environment } from '../environments/environment';
import { MatSidenav } from '@angular/material/sidenav';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MenuItem } from './Shared/interfaces/iMenu';
import { AuthService } from './Services/auth.service';
import { OdataService } from './Services/o-data.service';
import { AuthActions } from './Store/actions';
import { AppState } from './Store/reducers';
import { Store } from '@ngrx/store';
import { uiSelectors } from './Store';
import { AlertService, AlertType } from './Services/Utilities/alert.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    animations: [
        trigger('openClose', [
            state(
                'open',
                style({
                    width: '200px',
                })
            ),
            state(
                'mini',
                style({
                    width: '80px',
                })
            ),
            transition('open <=> mini', [animate('0.2s ease-in-out')]),
        ]),
    ],
})
export class AppComponent implements OnInit, AfterViewInit {

  loadApp() {
    console.log('OAuth load completed');
    this.appLoad = true;
  }
  
    @ViewChild('sidenav') sidenav!: MatSidenav;

    public title = 'angular-starter-project';
    public appLoad = false;
    public isOpen = true; // Always true, as the sidenav is always "open"
    public isMini = false; // Control the "mini" state
    public logo: string = '/assets/images/TrueData-RGB_Logo Primary.png';
    public isLoading: boolean = false;
    public menuItems: MenuItem[] = [
        { title: 'Dashboard', page: '/dashboard', iconClass: 'fa fa-home' },
        { title: 'User Permission', page: '/permission', iconClass: 'fas fa-users' },
        { title: 'TrueData Standard Filters', page: 'sysfilters', iconClass: 'fas fa-filter' },
        { title: 'Data Review', page: '/review-page', iconClass: 'fad fa-database' },
        { title: 'Settings', page: '/settings', iconClass: 'far fa-cog' },
    ];

    public oauthConfig: ServerlessOAuthConfig = {
        serverless: true,
        clientId: environment.omsConfig.ssoClientId,
        rbacEnabled: false,
        rbacAppName: 'TrueData',
        revalidateAfterMin: 15,
        sessionExpirationAlertInMin: 15,
        longestTimeToLiveHours: 8,
        autoLogoutOnSessionExpired: true,
        logoutUrl: environment.baseUrls.sso + '/closeBrowser.html',
        environmentConfig: environment
    };
    

    constructor(private alertService: AlertService, private authService: AuthService, private odataService: OdataService, private store: Store<AppState>, private cdr: ChangeDetectorRef, private serverlessOauthService: ServerlessOauthService, private applicationConfigurationService: ApplicationConfigurationService) { }

    ngOnInit(): void {
        this.authService.getToken().subscribe(
            (token) => {
                this.store.dispatch(AuthActions.getTokenSuccess({ token }));
            },
            (error) => {
                console.error('Error getting token:', error);
                this.alertService.notify(AlertType.Error, 'Failed to retrieve authentication token.');
                this.store.dispatch(AuthActions.getTokenFailure({ error }));
                this.store.dispatch(AuthActions.clearToken());
            }
        );
    }    

    ngAfterViewInit(): void {
        this.store.select(uiSelectors.selectLoading).subscribe((isLoading) => {
            this.isLoading = isLoading;
            this.cdr.detectChanges();
        })

        // this.alertService.notify(AlertType.Warning, "You got it")
        // this.alertService.confirm("Are you sure? ", "This is the Title", ()=>{
        //     console.log('Yes, confirm is working!!')
        // });
        // this.alertService.alert(AlertType.Warning, "Are you sure? ", "This is the Title")
    }

    toggleMini(): void {
        this.isMini = !this.isMini; // Toggle between mini and full view
    }
}
