export const DASHBOARD_IMAGES = 
{
    
    HISTORY : '/assets/images/History.png',
    WORKFLOW : '/assets/images/Workflow.png',
    LOGO : '/assets/images/TrueData-RGB_Logo Primary.png',
    MENU_LOGO: '/assets/images/TrueData-RGB_Wordmark Primary.png',
    INCPLT_RECORDS : '/assets/images/incomplete_records.png',
    ATTR_PIECHART : '/assets/images/Attribute_piechart.jpg',
    SOURCE_SCORE : '/assets/images/source_score.png'
}

export const TRUE_DATA_SEAL =  '/assets/images/TrueData-RGB_Seal Primary.png';
export const SRC = 'src';
export const LINK = 'link';
export const ENTITY = 'Mfr';
export const MASTER_TABLES = "MasterTables?$filter=MasterTableType eq 'Base'"
export const META_DATA = '$metadata'