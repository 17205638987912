import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoggerService {

  constructor() { }

  log(className: string, message: string, obj?: any): void {
    if (!environment.production) {
      console.log(`[${className}] ${message}`, obj);
    }
  }

  error(className: string, message: string, obj?: any): void {
    if (!environment.production) {
      console.error(`[${className}] ${message}`, obj);
    }
  }

  warn(className: string, message: string, obj?: any): void {
    if (!environment.production) {
      console.warn(`[${className}] ${message}`, obj);
    }
  }

  info(className: string, message: string, obj?: any): void {
    if (!environment.production) {
      console.info(`[${className}] ${message}`, obj);
    }
  }

  debug(className: string, message: string, obj?: any): void {
    if (!environment.production) {
      console.debug(`[${className}] ${message}`, obj);
    }
  }
}
