import { createReducer, on } from '@ngrx/store';
import * as UiActions from '../actions/ui-actions';
import { UiState } from '@app/Shared/interfaces/iUserInterface';
 
 
export const initialUiState: UiState = {
    isLoading: false,
    error: null,
};

export const uiReducer = createReducer(
    initialUiState,
    on(UiActions.startLoading, (state) => ({ ...state, isLoading: true })),
    on(UiActions.stopLoading, (state) => ({ ...state, isLoading: false })),
);
