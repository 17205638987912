import { Injectable } from '@angular/core';
import { AuthService } from '@app/Services/auth.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as AuthActions from '../actions/auth-actions';
 

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private authService: AuthService
  ) {}

  getToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.getToken),
      mergeMap(() =>
        this.authService.getToken().pipe(
          map(token => AuthActions.getTokenSuccess({ token })),
          catchError(error => of(AuthActions.getTokenFailure({ error })))
        )
      )
    )
  );
}
