import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmComponent } from './confirm/confirm.component';
import { AlertComponent } from './alert/alert.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogActions, MatDialogClose, MatDialogContent, MatDialogModule, MatDialogTitle } from '@angular/material/dialog';



@NgModule({
  declarations: [
    ConfirmComponent,
    AlertComponent,
    // MatDialogActions, 
    // MatDialogClose, 
    // MatDialogTitle, 
    // MatDialogContent
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule
  ],
  exports: [ConfirmComponent, AlertComponent]
})
export class AlertModule { }
