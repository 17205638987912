
import { iQueryParams } from '@app/Shared/interfaces/iQueryParams';
import { createAction, props } from '@ngrx/store';
 
// Loading UI actions
export const startLoading = createAction('[UI] START_LOADING');
export const stopLoading = createAction('[UI] STOP_LOADING');



