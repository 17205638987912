/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, filter, finalize, map, switchMap, tap } from 'rxjs/operators';
import { XMLParser } from 'fast-xml-parser'; // Correctly import XMLParser
import { Store } from '@ngrx/store';
import { AppState } from '@app/Store/reducers';
import { iEdmx } from '@app/Shared/interfaces/iEntity';
import { parseEdmxData } from '@app/Shared/util/parse';
import { environment } from '@environments/environment';
import { iButtonItem } from '@app/Shared/interfaces/iMenu';
import { selectToken } from '@app/Store/features/auth.feature';
import { AddParentIn } from '@app/Shared/models/add-parent-in.model';
import { RemoveParentIn } from '@app/Shared/models/remove-parent-in.model';
import { ChangeParentChildLinkKey } from '@app/Shared/models/change-parent-child-link-key.model';

const { oData: baseUrl, api: apiUrl } = environment.baseUrls;

@Injectable({
    providedIn: 'root',
})
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export class OdataService {
    constructor(private http: HttpClient, private store: Store<AppState>) {}

    getMetaData(query: string): Observable<any> {
        const url = `${baseUrl}${query}`;
        return this.http.get(url, { headers: { Accept: 'application/xml' }, responseType: 'text' }).pipe(
            map((xmlString: string) => {
                const edmx: iEdmx = this.parseXmlToJs(xmlString);
                return parseEdmxData(edmx);
            }),
            catchError((error) => {
                console.error('[ERROR]: FetchData', error);
                return throwError(() => new Error(`Error fetching XML data: ${error.message}`));
            })
        );
    }

    private parseXmlToJs(xmlString: string): iEdmx {
        try {
            const xmlParser = new XMLParser({
                ignoreAttributes: false,
                parseAttributeValue: true,
                attributeNamePrefix: '',
            });
            const parsed = xmlParser.parse(xmlString);

            // Use optional chaining to simplify the check
            if (parsed?.['edmx:Edmx']?.['edmx:DataServices']?.Schema) {
                return parsed;
            } else {
                throw new Error('Invalid XML format');
            }
        } catch (error) {
            throw new Error('Invalid XML format');
        }
    }

    addParent(model: AddParentIn): Observable<any> {
        const url: string = `${apiUrl}link/${model.masterTable}/${model.masterKey}/${model.linkTable}?relationship=Parent&newRelationKey=${model.newRelationKey}`;
        return this.http.post(url, null, { headers: { 'skip-error-handler': 'true' } }).pipe(map((response: any) => response));
    }

    changeParentChildLinkKey(model: ChangeParentChildLinkKey): Observable<any> {
        const url: string = `${apiUrl}link/${model.masterTable}/${model.masterKey}/${model.linkTable}/${model.linkKey}?newMasterKey=${model.newMasterKey}&relationship=${model.relationship}`;
        return this.http.put(url, null, { headers: { 'skip-error-handler': 'true' } }).pipe(map((response: any) => response));
    }
    

    removeParent(model: RemoveParentIn): Observable<any> {
        const url: string = `${apiUrl}link/${model.masterTable}/${model.masterKey}/${model.linkTable}/${model.linkKey}`;
        return this.http.delete(url, { headers: { 'skip-error-handler': 'true' } }).pipe(map((response: any) => response));
    }

    getOdata(odataQuery: string): Observable<any[]> {
        const url = `${baseUrl}${odataQuery}`;
        return this.http.get(url).pipe(
            map((response: any) => (Object.prototype.hasOwnProperty.call(response, 'value') ? response.value : response)),
            catchError((error) => {
                console.error('[ERROR]: Get OData Request Error:', error.message);
                throw error;
            })
        );
    }

    getRelations(query: string): Observable<any> {
        const url = `${apiUrl}${query}`;
        return this.http.get(url).pipe(
            map((response: any) => response),
            catchError((err) => {
                console.error('[ERROR]: Get Relationship Request Error', err.message);
                throw err;
            })
        );
    }

    getButtonList(): Observable<iButtonItem[]> {
        return of([
            { title: 'email', page: 'email', icon: 'email' },
            { title: 'share', page: 'share', icon: 'share' },
            { title: 'print', page: 'print', icon: 'print' },
        ]);
    }

    getParentData(masterTable: string, masterKey: string): Observable<any> {
        return this.http.get(`${apiUrl}link/${masterTable}/${masterKey}?relationship=Parent`);
    }

    getChildData(masterTable: string, masterKey: string): Observable<any> {
        return this.http.get(`${apiUrl}link/${masterTable}/${masterKey}?relationship=Child`);
    }

    getMergeData(masterTable: string, masterKey: string): Observable<any> {
        return this.http.get(`${apiUrl}merge/${masterTable}/${masterKey}`);
    }

    getMatchData(masterTable: string, masterKey: string): Observable<any> {
        return this.http.get(`${apiUrl}match/${masterTable}/${masterKey}`);
    }
}
